.hero-layout {
  position: relative;
  .c-main {
    min-height: 30rem !important;
    @media screen and (min-width: 991px) {
      padding: 2rem 10rem 2rem !important;
    }
    @media screen and (max-width: 992px) {
      // padding-left: 3.5rem !important;
      // padding-right: 3.5rem !important;
      // padding-top: 2rem !important;
      padding: 2rem !important;
    }
    .c-box {
      background-color: white;
    }
  }
  .home-page {
    .c-main {
      background-color: transparent !important;
    }
    @media screen and (max-width: 992px) {
      .c-main {
        padding: 0 !important;
        .c-box-home {
          .home-detail-wrapper {
            padding: 2rem 3rem;
            background-color: #131c42;
          }
          .manage-property {
            padding-left: 3rem;
            padding-right: 3rem;
          }
        }
      }
    }
  }

  .o-container {
    @media screen and (max-width: 992px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .c-main-home {
        padding: 2rem 3.5rem !important;
      }
    }
  }
  .hero-banner {
    position: absolute;
    width: 100%;
    height: 523.188px;
    top: 0;
    left: 0;
    background-color: #cfedfc;
    background-size: 100% 120%;
    background-image: url('../../images/hero-expanded.jpg');
    background-repeat: no-repeat;
    &.collapsed {
      height: 300px !important;
      top: -130px;
      background-color: #131c42;
      background-size: 450px;
      background-position: right;
      background-image: url('../../images/hero-collapsed.png');
    }
    @media screen and (max-width: 992px) {
      background-color: #131c42;
      background-image: none;
    }
  }
  .hero-banner-collapsed {
    margin-top: 4rem;
    position: relative;
    @media screen and (max-width: 1280px) {
      margin-left: 2rem;
      margin-right: 2rem;
      width: auto;
    }
    @media screen and (max-width: 992px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
