.c-header__content {
  margin: 0 !important;
}
#header-navbar {
  position: relative;
}
.nav-top-menu {
  position: absolute;
  display: flex;
  top: -43px;
  right: 0;
  background-color: $ywSkyBlue;
  padding: 10px;
  font-family: 'Poppins', 'Arial', 'sans-serif';
  text-decoration: none;
  a,
  div {
    margin: 0 20px;
    color: $headerLinkBluePrimary;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      color: $headerLinkBlueSecondary;
      text-decoration: underline;
    }
  }
  div {
    margin-left: 0;
  }
  svg {
    fill: $headerLinkBluePrimary;
  }
  &.account-disabled {
    padding: 1rem 1.5rem;
    #logout_link {
      margin-right: 0 !important;
    }
  }
}
.nav-bottom-menu {
  position: relative;
  display: flex;
  align-items: center;
  top: 27px;
  .right {
    display: flex;
    margin-right: 40px;
    .home-link,
    .menu-link-wrapper {
      color: $headerLinkBluePrimary;
      cursor: pointer;
      text-decoration: none;
      margin-right: 30px;
      &:hover {
        color: $headerLinkBlueSecondary;
        text-decoration: underline;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .menu-link-wrapper {
    display: flex;
    align-items: center;
    margin-right: 0 !important;
    @media screen and (min-width: 992px) {
      &.no-active-property {
        display: none;
      }
    }
  }
  .menu-chevron {
    transition: all 150ms linear;
    &.inverse {
      transform: rotate(0);
    }
  }
  .menu-link-border {
    position: absolute;
    display: block;
    top: -10px;
    width: 60%;
    left: 20%;
    right: 0;
    height: 8px;
    background-color: #58b6d2;
  }
}
.burger-menu {
  display: none;
  background-image: url('../../images/burgerMenu.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 41px;
  height: 23.5px;
  &.is-open {
    background-image: url('../../images/burgerMenuClose.svg');
  }
}
.dropdown-menu-content {
  position: absolute;
  width: 300px;
  top: 140%;
  right: 19%;
  padding: 10px;
  background: #fff;
  border: solid 2px #58b6d2;
  text-align: left;
  h2 {
    color: #2441e5;
  }
  ul {
    margin: 0 2rem;
    list-style: none;

    li {
      padding: 5px;
      a,
      button {
        font-family: arial !important;
        color: black !important;
        text-decoration: none !important;
        &:hover {
          color: #2441e5 !important;
          text-decoration: underline !important;
        }
      }
    }
  }
  .get-in-touch-mobile {
    display: none !important;
    width: 80%;
    margin-left: 10%;
  }
}
#root {
  &.menu-open {
    .c-header {
      border-bottom: #58b6d2 solid 3px;
    }
  }
}
@media screen and (min-width: 992px) {
  #header-navbar {
    .menu-burger {
      display: none !important;
    }
  }
}
@media screen and (max-width: 991px) {
  #root {
    &.menu-open {
      overflow: hidden !important;
    }
  }
  #header-navbar {
    .nav-top-menu {
      display: none !important;
    }
    .nav-bottom-menu {
      top: 0;
      .home-link,
      .get-in-touch,
      .menu-chevron {
        display: none;
      }
      .burger-menu {
        display: block;
      }
      .right {
        margin: 0;
        .menu-link-wrapper {
          text-decoration: none;
        }
      }
      .menu-link-border {
        display: none;
      }
    }
    .menu-burger {
      display: block;
    }
  }
  .c-header {
    padding: 2rem 0 !important;
  }
  .dropdown-menu-content {
    position: fixed;
    top: 110px;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;

    .get-in-touch-mobile {
      display: block !important;
    }
  }
}
