@import 'node_modules/yw-ui.v2/dist/scss/01_settings/colours';
@import './variables';
@import './components/nav-bar-items';
@import './components/home';
@import './components/your-balance';
@import './components/hero-layout';
@import './components/bill-preferences';
@import './components/bills-and-payments';
@import './components/bill-item';
@import './components/add-property';
@import './components/contact-information';
@import './components/properties';
@import './components/something-went-wrong';

html,
body,
#root,
.c-app-online-account {
  height: 100%;
}

.c-app-online-account {
  display: flex;
  flex-direction: column;
  .c-header {
    z-index: 1;
    margin-bottom: 0 !important;
    padding: 3rem 0;
    border-bottom: 3px solid #ffffff;
    &.u-mb--400 {
      margin-bottom: 0 !important;
    }
    @media screen and (min-width: 992px) {
      min-height: auto;
    }
  }
}
.c-app {
  flex-grow: 1;
  min-height: auto !important;
  background-color: #ffffff !important;
  &.home-page {
    background-color: #f6f6f6 !important;
  }
}
.c-footer {
  min-height: 65px;
}
.c-loader {
  position: fixed !important;
}

.no-underline {
  text-decoration: none;
}

.older-bills-list {
  button {
    font-family: 'Poppins', Arial, sans-serif;
    margin-bottom: 0 !important;
  }
  .c-accordion-single__children {
    min-width: 100%;
  }
}

.previous-payments-list {
  button {
    font-family: 'Poppins', Arial, sans-serif;
  }
  .c-accordion-single__children {
    min-width: 100%;
  }
}
.accordion-single-toggle {
  margin: 3rem 0 1rem !important;
}
.contact-information-wrapper {
  .c-message {
    .c-message__title {
      font-family: 'Poppins', 'Arial', 'sans-serif';
      font-size: 1.8rem;
      line-height: 2.7rem;
      margin-bottom: 0.936rem !important;
      margin-top: 0;
      font-weight: 500;
    }
  }
}
#yourBalance,
#lastPayment {
  a {
    font-family: 'Poppins', Arial, sans-serif;
  }
}
.no-breadcrumb {
  padding-top: 48px;
}

.c-button--link-inline {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #124a87 !important;
  line-height: 25.2px !important;
}

.c-button--default-outline {
  border-color: #000000 !important;
}

.c-review-details__value {
  color: #131c42 !important;
}

.c-button-row__item {
  font-family: 'Poppins', sans-serif !important;
}

.c-border {
  border: 1px solid #58b6d2;
}

.c-content-group {
  #breadcrumbs {
    a {
      color: #2441e5;
    }
  }
  .c-content-group {
    a {
      color: #124a87;
    }
  }
  .c-form-row {
    &.has-error {
      background-color: #f6f6f6;
      border-left: 0.8rem solid #de6e68;
      padding: 3.2rem;
      .c-form-row__msg {
        color: #131c42;
        font-size: 18px;
      }
      @media screen and (max-width: 767px) {
        padding: 2.4rem 1.6rem;
      }
    }
  }
}
.c-content-expander.is-expanded {
  border: 1px solid #58b6d2;
}
.c-input {
  border-width: 0.1rem !important;
  &.has-error {
    border-color: #131c42 !important;
  }
}
.DayPicker-Caption > div {
  font-family: 'Poppins', sans-serif !important;
}

button[data-qa='back-button'] {
  margin-right: 7rem;
}

.c-footer__container {
  display: inline-grid !important;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.ot-sdk-show-settings.c-content-filter-block_button {
  all: unset;
  font-family: inherit;
  font-size: 14px;
  color: white;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }
}
