.contact-information-wrapper {
  .c-input:disabled {
    color: #a9a9a9;
  }
  .c-message {
    background-color: #f4f5f7 !important;
    border-left: #20a1c9 4px solid;
    .c-message-block__children {
      p {
        margin-bottom: 0 !important;
      }
    }
  }
  .c-radio-tile {
    margin-bottom: 0 !important;
    label {
      padding-bottom: 0.5rem;
    }
  }
  .c-form-row__label-text {
    // margin: 0 0 1rem 0;
    font-weight: bolder;
  }

  @media screen and (min-width: 768px) {
    .c-form-row {
      margin: 0 -1rem 4rem -1rem !important;
    }
  }

  @media screen and (max-width: 991px) {
    #forename,
    #surname,
    #mobileTelephone {
      width: 100%;
    }
  }
}
