.properties-page {
  .c-item-card {
    margin-bottom: 3rem !important;
    &__icon-left {
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      &__icon-right {
        min-width: auto;
      }
    }
  }

  .c-pagination {
    margin-bottom: 3rem;
  }
}
