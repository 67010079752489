.add-property-page {
  .page-title {
    margin-bottom: 1rem !important;
  }
  .you-will-need {
    .c-message__title {
      font-size: 1.8rem;
    }
  }
  .c-review-details {
    padding: 0;
  }
}
.bill-information-page {
  @media screen and (min-width: 768px) {
    .c-form-row {
      margin: 0 -1rem 3rem -1rem;
    }
  }
  #dateOnBill_form-row {
    margin-bottom: 0 !important;
    .c-datepicker {
      margin-bottom: 0 !important;
    }
  }
}
