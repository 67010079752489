.bill-item-wrapper {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 20px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);

  button {
    color: #2441e5;
    font-family: 'Poppins', Arial, sans-serif;
    margin-left: 5px;
  }

  img {
    width: 20px;
    height: 22px;
    margin-left: 4px;
    margin-bottom: -4px;
    fill: #124a87;
  }
}
