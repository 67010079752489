.bills-payments-wrapper {
  .c-info-card {
    min-height: 265.59px;
    h3 {
      span {
        margin-left: 10px;
      }
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 480px) {
      h3 {
        font-size: 4rem;
      }
    }
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #131c42;
      color: #ffffff;
      border-collapse: collapse;
    }
    tr {
      td {
        padding: 1rem 0.75rem;
        &:first-child {
          padding-left: 1.75rem;
        }
        &:last-child {
          padding-right: 1.75rem;
        }
      }
      &.odd {
        background-color: #cfedfc;
      }
    }
    td {
      min-width: 130px;
    }
    .no-payments {
      text-align: center;
    }
  }
  .payments-table-wrapper {
    width: 100%;
    @media screen and (max-width: 460px) {
      overflow-y: scroll;
      table {
        width: 500px;
      }
    }
  }
}
