.bill-preferences-scene {
  .c-message__title {
    font-family: 'Poppins', 'Arial', 'sans-serif';
  }
  .c-form-row__label-text {
    margin-bottom: 0 !important;
  }
  .c-radio-tile {
    margin-bottom: 0 !important;
  }
  .c-radio {
    padding-bottom: 0 !important;
    font-weight: 500 !important;
  }
  .c-form-row {
    margin-bottom: 0 !important;
  }
  .c-button--link-inline {
    font-family: 'Poppins', Arial, sans-serif;
    margin-bottom: 0 !important;
  }
}
