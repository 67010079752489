.c-home-layout {
  .c-header {
    margin-bottom: 0 !important;
    min-height: 130px;
    .c-header__content {
      padding-right: 0.75rem;
    }
  }
  .c-hero-banner {
    height: 498.188px;
  }
}
.home-detail-wrapper {
  .customer-details {
    color: $white;
    h1,
    h3 {
      color: $white;
    }
    h1 {
      margin-top: 10.72px;
    }
    @media screen and (max-width: 480px) {
      #userAddress {
        max-width: 280px;
      }
      #accountReference,
      #accountStartDate {
        span {
          display: block;
        }
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      #accountReference,
      #accountStartDate {
        span {
          display: block;
        }
      }
    }
  }
  .c-info-card {
    min-height: 265.59px;
    h3 {
      span {
        margin-left: 10px;
      }
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 480px) {
      h3 {
        font-size: 4rem;
      }
    }
  }
}

.manage-property {
  margin-top: 4rem;
  .c-message {
    &#manage-property-notice {
      background-color: #ffffff;
    }
    border-left: #20a1c9 4px solid;
    .c-message__title {
      font-family: 'Poppins', 'Arial', 'sans-serif';
      font-size: 1.8rem;
      line-height: 2.7rem;
      margin-bottom: 0.936rem !important;
      margin-top: 0;
      font-weight: 500;
    }
  }
  .action-tile-container {
    .c-action-tile {
      min-height: 100%;
      &__title {
        font-size: 2.2rem;
        line-height: normal;
      }
      &__icon {
        &.IconPaperPlane {
          min-width: 6.5rem;
        }
        &.IconPaymentCard {
          min-width: 6.5rem;
        }
        &.IconHouse3 {
          min-width: 5rem;
        }
        &.IconTruck {
          min-width: 5.5rem;
        }
      }
      .tile-container {
        flex-grow: 1;
      }
    }
    #closeAnAccountTile_info {
      white-space: break-spaces;
    }
  }
  @media screen and (max-width: 992px) {
    padding: 0 3.5rem 2rem;
    .c-heading-block {
      margin-top: 0 !important;
    }
    .action-tile-container {
      .c-action-tile {
        margin-bottom: 2rem;
      }
    }
  }
}

.c-primary-action-column-block {
  background-color: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.primaryActionHome {
  .balance {
    font-size: 6rem;
  }
  .credit-text {
    margin-left: 20px;
  }
}

.c-primary-action-column-block_heading {
  line-height: 40px;
  line-height: 2.85714rem;
  font-size: 32px;
  font-size: 2.28571rem;
  margin: 0 0 32px;
  text-decoration: none;
}

.c-modal-dialog-warpper {
  .c-modal-dialog {
    overflow-y: auto !important;
  }
}
@media (min-width: 768px) {
  .c-modal-dialog-warpper {
    .c-modal-dialog {
      max-width: 60rem !important;
    }
  }
}
